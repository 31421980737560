import loadable from '@loadable/component';
import React, { Suspense } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import AdminLayout from 'layouts/Admin'

const Login = loadable(() => import('views/Login'));

function AppRoutes() {
  return (
    <Suspense fallback={null}>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route path="/" render={(props) => <AdminLayout {...props} />} />
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
    </Suspense>
  )
}

export default AppRoutes
