import React from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import logo from 'assets/img/logo.png'
import { FaBell, FaUserAlt, FaSignOutAlt } from 'react-icons/fa'
import { IoMdMail } from 'react-icons/io'
import store from 'store'

const menus = [
 /* {
    path: '/dashboard',
    name: 'Dashboard',
  },
  {
    path: '/payment',
    name: 'Payment',
  },
  {
    path: '/report',
    name: 'Report',
  },
  {
    path: '/upload',
    name: 'Upload',
  },*/
  {
    path: '/eligibility-check',
    name: 'Eligibility-Check',
  },
  {
    path: '/gop',
    name: 'Pre-Approval',
  },
  {
    path: '/claim',
    name: 'Claim',
  },
]

const AdminNavbar = (props) => {
  const { pathname } = useLocation()
  const history = useHistory()
  const user = store.get("user")

  function _handleLogOut() {
    store.remove('user')
    store.remove('loggedIn')
    store.remove('memberPlans')
    store.remove('searchString')
    history.push('/login')
  }

  console.log("menu",props)
  return (
    <header
      className="d-flex align-items-center justify-content-between header"
      style={{
        backgroundColor: '#a7a7a7',
        height: 80,
        padding: '10px 20px',
      }}
    >
      <img src={logo} alt="logo" style={{ maxHeight: 48, width: 'auto', display: 'block', height: '100%' }} />
      <ul className="d-flex align-items-center ml-4" style={{ flex: 1 }}>
        {(props.location.pathname !== "/providerList") && menus.map((menu, index) => (
          <li key={index} className="text-uppercase">
            <Link
              className="px-3"
              to={menu.path}
              style={{ fontSize: 16, color: pathname.split('/')?.[1] === menu.name.toLowerCase() ? '#000' : '#fff' }}
            >
              {menu.name}
            </Link>
          </li>
        ))}
      </ul>
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center" style={{ lineHeight: 1 }}>
          <FaBell style={{ fontSize: 20 }} className="px-1 pointer" />
          <IoMdMail style={{ fontSize: 20 }} className="px-1 pointer" />
          <FaUserAlt style={{ fontSize: 20 }} className="px-1 pointer" />
          <span className="px-2" style={{ borderRight: '2px solid' }}>
            {user?.roleProvider?.name ?? ""}
          </span>
          <span className="px-2 pointer" onClick={_handleLogOut}>
            Log Out
          </span>
          <FaSignOutAlt className="pointer" style={{ fontSize: 20 }} onClick={_handleLogOut} />
        </div>
      </div>
    </header>
  )
}

export default AdminNavbar
