import React from 'react'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import 'assets/scss/main.scss'
import 'react-datepicker/dist/react-datepicker.css'
import 'handsontable/dist/handsontable.full.css';

import ReactDOM from 'react-dom'
import Core from './core/AppRoutes'

ReactDOM.render(<Core />, document.getElementById('root'))
