import loadable from '@loadable/component'

const Dashboard = loadable(() => import('./views/Dashboard'))
const EligibilityCheck = loadable(() => import('./views/EligibilityCheck'))
const Claim = loadable(() => import('./views/Claim'))
const ClaimList = loadable(() => import('./views/ClaimList'))
const GOP = loadable(() => import('./views/GOP'))
const Payment = loadable(() => import('./views/Payment'))
const Report = loadable(() => import('./views/Report'))
const Upload = loadable(() => import('./views/Upload'))
const ProviderList = loadable(() => import('./views/ProviderList'))

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/eligibility-check',
    exact: true,
    name: 'Eligibility-Check',
    component: EligibilityCheck,
  },
  {
    path: '/gop',
    exact: true,
    name: 'Pre-Approval',
    component: ClaimList,
  },
  {
    path: '/claim/:type/:clno',
    name: 'Claim',
    component: Claim,
  },
  {
    path: '/claim',
    name: 'Claim',
    component: ClaimList,
  },
  {
    path: '/gop/:type/:id',
    name: 'Pre-Approval',
    component: Claim,
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment,
  },
  {
    path: '/report',
    name: 'Report',
    component: Report,
  },
  {
    path: '/upload',
    name: 'Upload',
    component: Upload,
  },
  {
    path: '/providerList',
    name: 'Provider List',
    component: ProviderList,
  },
]
export default routes
