import React, { useEffect, useRef } from 'react'
import { useLocation, Route, Switch, Redirect } from 'react-router-dom'
import { Container } from 'reactstrap'
import AdminNavbar from 'components/Navbars/AdminNavbar'
import store from 'store'

import routes from 'routes'

const Admin = (props) => {
  const mainContent = useRef(null)
  const location = useLocation()

  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    mainContent.current.scrollTop = 0
  }, [location])

  const pageName = location.pathname.split('/')?.[1]?.split('-').join(' ')
  return (
    <>
      <AdminNavbar {...props} />
      <h6
        className="text-white font-weight-bold text-capitalize breadcrumbs"
        style={{ backgroundColor: '#51bcda', padding: '12px 16px' }}
      >
        {pageName.toLowerCase() === 'gop' ? 'Pre-Approval' : pageName}
      </h6>
      <main id="main" ref={mainContent}>
        <Container fluid className="content">
          <Switch>
            {routes.map(({ component: Component, ...rest }, key) => (
              <Route
                key={key}
                exact={rest.exact || false}
                path={rest.path}
                render={(props) =>
                  store.get('loggedIn') ? (
                    <Component {...props} {...rest} />
                  ) : (
                    <Redirect
                      to={{
                        pathname: '/login',
                        state: { from: props.location },
                      }}
                    />
                  )
                }
              />
            ))}
            <Redirect from="*" to="/eligibility-check" />
          </Switch>
        </Container>
      </main>
    </>
  )
}

export default Admin
